exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brew-guide-tsx": () => import("./../../../src/pages/brew-guide.tsx" /* webpackChunkName: "component---src-pages-brew-guide-tsx" */),
  "component---src-pages-clock-tsx": () => import("./../../../src/pages/clock.tsx" /* webpackChunkName: "component---src-pages-clock-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notification-tsx": () => import("./../../../src/pages/notification.tsx" /* webpackChunkName: "component---src-pages-notification-tsx" */),
  "component---src-pages-pedora-tsx": () => import("./../../../src/pages/pedora.tsx" /* webpackChunkName: "component---src-pages-pedora-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */)
}

