import Lottie from 'lottie-web'
import MustacheLottie from 'styles/lottie/mustache-lottie.json'

let lottieAnimation

export const onClientEntry = () => {
  document.getElementById('___gatsby').style.display = 'none'
  const animationContainer = document.getElementById('loading-lottie') as HTMLElement
  lottieAnimation = Lottie.loadAnimation({
    container: animationContainer,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: MustacheLottie,
  })
}

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById('___gatsby').style.display = ''
    document.getElementById('___loader').style.display = 'none'
    lottieAnimation.stop()
  }, 2000)
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  document.getElementById('___gatsby').style.display = 'none'
  document.getElementById('___loader').style.display = 'flex'
  lottieAnimation?.play()

  setTimeout(function () {
    document.getElementById('___gatsby').style.display = ''
    document.getElementById('___loader').style.display = 'none'
    lottieAnimation?.stop()
  }, 2000)
}
